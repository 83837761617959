<template>
  <div>
    <div class="discussion-board">
      <div class="subnav-wrapper">
        <div class="container">
          <router-link
            :to="{ name: 'MyDiscussionBoard' }"
            class="btn small-cta"
          >
            <img src="../assets/arrow.svg" alt="" /> Back to discussion board
          </router-link>
        </div>
      </div>
      <div class="container">
        <div class="outer-header">
          <h5>Have Any Questions, Let’s Discuss Here</h5>
        </div>
        <div class="info-section">
          <div class="_section-head">
            <div v-if="discussionLoading" class="loading">Loading...</div>
            <h5 v-if="!discussionLoading">{{ discussion.discussion }}</h5>
            <ul
              v-if="!discussionLoading"
              class="
                d-flex
                flex-row
                justify-content-between
                w-100
                list-unstyled
              "
            >
              <li>Course : {{ discussion.program.program_title }}</li>
              <li>Subject : {{ discussion.subject.subject_title }}</li>
              <li>Teacher : {{ discussion.teacher.teacher_name }}</li>
            </ul>
          </div>
          <DiscussionSlot
            ref="discussionSlot"
            :togglePostCount="false"
            @editDiscussion="getDiscussion"
          />
          <div ref="reply_form"></div>
          <form
            @submit.prevent="saveDiscussionComment"
            ref="commentContainer"
            class="p-3"
          >
            <div class="form-group col-md-12">
              <div class="mb-3">
                <label class="form-label">Comment <span>*</span></label>
                <textarea
                  class="form-control"
                  v-model="discussionComment"
                ></textarea>
                <div
                  class="input-errors"
                  v-for="error of v$.discussionComment.$errors"
                  :key="error.$uid"
                >
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>
            <div class="text-right">
              <button type="submit" class="btn cta-primary">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DiscussionSlot from "@/components/DiscussionSlot.vue";
import DiscussionService from "@/services/DiscussionService";
import useVuelidate from "@vuelidate/core";
import { required, maxLength, helpers } from "@vuelidate/validators";

export default {
  name: "DiscussionBoard",
  components: {
    DiscussionSlot,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      discussionLoading: true,
      discussionComment: "",
      discussionId: "",
      fullPage: true,
    };
  },
  validations() {
    return {
      discussionComment: {
        required: helpers.withMessage(
          "Please enter discussion comment",
          required
        ),
        maxLength: maxLength(2400),
      },
    };
  },
  created() {
    this.fetchSubscribedProgramDiscussion();
  },
  methods: {
    async fetchSubscribedProgramDiscussion() {
      let program_id =
        this.$route.params.program_id != undefined
          ? this.$route.params.program_id
          : "";
      let discussion_id =
        this.$route.params.discussion_id != undefined
          ? this.$route.params.discussion_id
          : "";
      let params = {
        program_id: program_id,
        discussion_id: discussion_id,
      };
      await DiscussionService.getSubscribedProgramDiscussionDetails(params)
        .then((response) => {
          console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.discussionLoading = false;
            this.discussion = response.data.discussion;
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            let errorsObject = response.data.errors;
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    saveDiscussionComment() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
      });
      let program_id =
        this.$route.params.program_id != undefined
          ? this.$route.params.program_id
          : "";
      let discussion_id =
        this.$route.params.discussion_id != undefined
          ? this.$route.params.discussion_id
          : "";
      let params = {
        parent_discussion_id: discussion_id,
        program_id: program_id,
        discussion: this.discussionComment,
        discussion_id: this.discussionId,
      };
      DiscussionService.postDiscussionComment(params)
        .then((response) => {
          loader.hide();
          if (response.data.status === "SUCCESS") {
            this.$toast.success(response.data.message);
            this.$refs.discussionSlot.switchRoute();
            this.v$.$reset();
            this.discussionComment = "";
            this.discussionId = "";
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            let errorsObject = response.data.errors;
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getDiscussion: function (id) {
      const data = {
        discussion_id: id,
      };
      this.isEditing = true;
      this.scrollToElement();
      DiscussionService.getDiscussion(data)
        .then((result) => {
          if (result.data.status == "SUCCESS") {
            this.discussionComment = result.data.discussion.discussion;
            this.discussionId = result.data.discussion.id;
          } else if (result.data.status == "ERROR") {
            this.$toast.error(result.data.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    scrollToElement() {
      const el = this.$refs.reply_form;
      if (el) {
        el.scrollIntoView({
          behavior: "auto",
          block: "center",
          inline: "center",
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/style/discussion-board.scss";
</style>
